<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "mega-containers",
              "select-container",
              "select-container"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('mega-containers', 'select-container', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfContainers && listOfContainers.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="container in listOfContainers"
        :key="container.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="selectMegaContainerContainer(container)"
        >
          <span class="label">
            <span class="highlight">{{ container.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="
                selectedContainers.filter(item => item.id === container.id)
                  .length
              "
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";
export default {
  name: "MegaContainerSelectContainer",
  mixins: [paginationMixin],
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: ""
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState("megaContainers", [
      "megaContainersContainers",
      "containersList",
      "selectedContainers"
    ]),
    ...mapState("containers", ["containerAccessTypes"]),
    listOfContainers() {
      const query = this.searchQuery;
      if (this.containersList && this.containersList.length) {
        if (query && query.length > 1) {
          return this.containersList.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.containersList;
        }
      }
      return null;
    },
    routeParams() {
      const { access_type_id, mega_container_id } = this.$route.params;
      return access_type_id || mega_container_id;
    }
  },
  watch: {
    routeParams() {
      this.getData();
    }
  },
  methods: {
    ...mapActions("megaContainers", [
      "getMegaContainersContainers",
      "getContainersList",
      "selectMegaContainerContainer"
    ]),
    getData() {
      const { access_type_id, mega_container_id } = this.$route.params;
      const { containerAccessTypes } = this;
      if (mega_container_id && !this.selectedContainers) {
        this.getMegaContainersContainers(mega_container_id);
      }

      if (
        access_type_id &&
        containerAccessTypes &&
        containerAccessTypes.data &&
        containerAccessTypes.data.length
      ) {
        const filtered = containerAccessTypes.data.filter(
          item => Number(item.id) === Number(access_type_id)
        );
        if (filtered && filtered.length) {
          this.getContainersList({
            access_type_ids: [filtered[0].id]
          });
        }
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
